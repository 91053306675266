import { HostListener, Component, OnInit, Inject } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { RoomService } from '../../services/room.service';
import { UserService } from '../../services/user.service';

import { User } from '../../models/user';
import { Room } from '../../models/room';
import { Card } from '../../models/card';
import { Message } from '../../models/message';
import { Cell } from '../../models/cell';

@Component({
	selector: 'app-bingo',
	templateUrl: './bingo.component.html',
	styleUrls: ['./bingo.component.css'],
	providers: [RoomService, UserService]
})
export class BingoComponent implements OnInit {

	public userPlayer: User;
	public userOwner: User;
	userAux: User;
	public roomCreate: Room;
	public roomJoin: Room;
	public card: Card;
	public meessage: Message;
	public cell : Cell;
	public roomExists: boolean;
	public correctUserCreate: boolean;
	public correctUserJoin: boolean;
	socketId: string;

	constructor(
		private _roomService: RoomService,
		private _userService: UserService,
		private _router: Router,
		private _route: ActivatedRoute
	) { 
		var roomGenerate = this.generateRandomRoom();
		this.card = new Card([new Cell(0, false), new Cell(0, false), new Cell(0, false), new Cell(0, false)], 
			[new Cell(0, false), new Cell(0, false), new Cell(0, false), new Cell(0, false)], 
			[new Cell(0, false), new Cell(0, false), new Cell(0, false), new Cell(0, false)]);
		this.userPlayer = new User('', '', null, '', this.card);
		this.userOwner = new User('', '', roomGenerate, '', this.card);
		this.userAux = new User('', '', null, '', this.card);
		this.roomJoin = new Room('', null, null, null, null, '');
		this.roomCreate = new Room('', roomGenerate, [0], [0], 1, '');
		this.roomExists = true;
		this.correctUserCreate = true;
		this.correctUserJoin = true;
	}

	ngOnInit(): void { 
	  	// Solicitamos el socketId
	  	this._userService.requestSocketId();

	  	this._userService
	  		.getSocketId()
	  		.subscribe((data: string) => {
	  			this.socketId = data; 
	  		});
	}

	onSubmitAndJoin(form){
		if (this.userPlayer.name.length > 3) {
			// Asignamos la sala y el socket obtenido
			this.userPlayer.roomId = this.roomJoin.code;
			this.userPlayer.socketId = this.socketId;
			
			// Generamos el cartón y lo asignamos al usuario
			var card = this._roomService.generateCard();
			this.userPlayer.card.row1 = card[0];
			this.userPlayer.card.row2 = card[1];
			this.userPlayer.card.row3 = card[2];

			this.checkIfRoomIdExists(this.userPlayer.roomId, this.userPlayer);
		} else {
			this.correctUserJoin = false;
		}
		
	}

	onSubmitCreateAndJoin(form){
		if (this.userOwner.name.length > 3) {
			// Asignamos la sala y el socket obtenido
			this.userOwner.roomId = this.roomCreate.code;
			this.userOwner.socketId = this.socketId;
		  		
			var card = this._roomService.generateCard();
			this.userOwner.card.row1 = card[0];
			this.userOwner.card.row2 = card[1];
			this.userOwner.card.row3 = card[2];

			// Actualizar la base de datos
			this._userService
				.saveUser(this.userOwner)
				.subscribe(
					response => {
						// Actualizar la sala
						// Actualizamos los datos restantes de la sala a crear
						this.roomCreate.owner = this.userOwner.socketId;

						this.roomCreate.numsTotal.splice(0, this.roomCreate.numsPlayed.length);

						for (let i = 1; i <= 90; i++) {
							this.roomCreate.numsTotal.push(i);
						}

						this.roomCreate.numsPlayed.splice(0, this.roomCreate.numsPlayed.length);

						this.roomCreate.usersConnected = 1

						this._roomService
							.saveRoom(this.roomCreate)
							.subscribe(
				  				response => {
				  					// console.log("Sala creada correctamente");
				  					this._userService.joinRoom(this.userOwner);
				  					this._router.navigate(['/bingo/room/' + this.roomCreate.code]);
				  				},
								error => {
									console.log(error);
								}
					  		);

						},
						error => {
							console.log(error);
						}
				);
		} else {
			this.correctUserCreate = false;
		}
		
	}

	generateRandomRoom(): number{
		var numberGenerate: number;
		var roomCodeCorrect: boolean = false;
		
		numberGenerate = this._roomService.generateRandomIntervalNumbers(10000000, 99999999, 1)[0];

		this._roomService
			.getRoomByCode(numberGenerate)
			.subscribe (
				response => {		
					if (response.room.length == 0){
						roomCodeCorrect = true;
					}
				},
				error => {
					console.log(error);
				}
		);

		return numberGenerate;
	}

	checkIfRoomIdExists(code: Number, user: User){
		this._roomService
			.getRoomByCode(code)
			.subscribe(
				response => {
					if (response.room[0]){
						if (response.room[0].code == code) {
							this.roomExists = true;

							// Actualizar el número de usuarios conectados
							this.roomJoin = response.room[0];
							this.roomJoin.usersConnected = this.roomJoin.usersConnected + 1

							this._roomService
							.updateRoom(this.roomJoin)
							.subscribe(
				  				response => {
				  					this._roomService.updateUsersConnected(this.roomJoin);
				  				},
								error => {
									console.log(error);
								}
					  		);

			      			// Guardar al usuario en la bd
							this._userService
								.saveUser(user)
								.subscribe(
				      				response => {
				      					// Añadimos el socket del usuario a la sala creada
				      					this._userService.joinRoom(this.userPlayer);
				      					// Avisamos al resto de la sala
				      					let text: String = user.name + " se ha conectado";
										let message: Message = new Message(user.roomId, user.name, text);
										this._userService.newUserConnected(message);

				      					this._router.navigate(['/bingo/room/' + this.roomJoin.code]);
				  					},
				  					error => {
				  						console.log(error);
				  					}

				      			);
						}
					} else {
						console.log("no entra");
						this.roomExists = false;
					}
				},
				error => {
					console.log(error);
				}	
		);
	}

	goToGameGuide(){
		this._router.navigate(['/bingo/guide']);
	}
}