<!DOCTYPE html>
<html lang="es">
<head>
	<meta charset="utf-8">
	<title>Bingo en CasApp</title>
</head>
	<body>
		<mat-toolbar id="app-toolbar" class="backgroundToolbar">
		  	<mat-toolbar-row class="textSpecify">
			    <span>Bingo en CasApp</span>

			    <span class="example-fill-remaining-space"></span>
			    <span class="align-center"></span>
			    <span class="spaceButtonsToolbar"></span>
			    <button mat-button class="buttonToolbar" (click)="goToGameGuide()">Instrucciones</button>
			</mat-toolbar-row>
		</mat-toolbar>

		<div class="firstText">
			<h2>¿Cómo quieres jugar?</h2>
		</div>

		<table class="initialPannel">
			<td>
				<div class="chooseGames">
					<div class="game">
						<p class="title">Ser anfitrión</p>
		
						<form #createRoomForm="ngForm" (ngSubmit)="onSubmitCreateAndJoin(createRoomForm)">
							<p>
								<label for="code">Código de la sala</label><br>
								<input type="text" name="codeGenerated" #codeGenerated="ngModel" [(ngModel)]="roomCreate.code" disabled>
							</p>
		
							<p>
								<label for="name">Introduce tu nombre</label><br>
								<input type="text" name="name" #name="ngModel" [(ngModel)]="userOwner.name" required /><br>
								<span class="form_error" *ngIf="name.touched && !name.valid">
									El nombre es obligatorio
								</span>
								<span class="form_error" *ngIf="!correctUserCreate">
									El nombre debe contener al menos 4 carácteres 
								</span>
							</p>
						
						<input type="submit" value="CREAR" [disabled]="!createRoomForm.form.valid">
							
						</form>
					</div>
				</div>
			</td>

			<td>
				<div >
					<h3>Tambíen puedes jugar en tu dispositivo Android</h3>
					<h3>Descarga Bingo en CasApp en tu móvil</h3>
				</div>
					
				<a href="https://play.google.com/store/apps/details?id=com.srirappcha.bingoencasapp&gl=ES" target="_blank"><img class="imageGooglePlay" src="../../../assets/img/google_play_store.png"/></a>
			</td>

			<td>
				<div class="chooseGames">

					<div class="game">
						<p class="title">Unirme a una sala</p>
		
						<form #roomForm="ngForm" (ngSubmit)="onSubmitAndJoin(roomForm)">
							<p>
								<label for="code">Introduce el código de la sala</label><br>
								<input type="number" name="code" #code="ngModel" [(ngModel)]="roomJoin.code" required maxlength="8"/><br>
								<span class="form_error" *ngIf="code.touched && !code.valid">
									El código es obligatorio
								</span>
								<span class="form_error" *ngIf="!roomExists">
									No existe ninguna sala con el código introducido
								</span>
							</p>
		
							<p>
								<label for="name">Introduce tu nombre</label><br>
								<input type="text" name="name" #name="ngModel" [(ngModel)]="userPlayer.name" required /><br>
								<span class="form_error" *ngIf="name.touched && !name.valid">
									El nombre es obligatorio
								</span>
								<span class="form_error" *ngIf="!correctUserJoin">
									El nombre debe contener al menos 4 carácteres 
								</span>
							</p>
							
							<input type="submit" value="UNIRME" [disabled]="!roomForm.form.valid">
						</form>
					</div>
					
				</div>
			</td>
		</table>
	</body>
</html>
