import { User } from './user';

export class Room{

	constructor(
		public _id: string,
		public code: Number,
		public numsTotal: [Number],
		public numsPlayed: [Number],
		public usersConnected: number,
		public owner: String
	){}
};