<!DOCTYPE html>
<html lang="es">
<head>
	<meta charset="utf-8">
	<title>Bingo en CasApp</title>
</head>
	<body>
		<mat-toolbar id="app-toolbar" class="backgroundToolbar">
		  	<mat-toolbar-row>
			    <span>Bingo en CasApp</span>

			    <span class="example-fill-remaining-space"></span>
			    <span class="align-center"></span>
			    <span class="spaceButtonsToolbar"></span>
			    <button mat-button class="buttonToolbar" (click)="mainPage()">Inicio</button>
			</mat-toolbar-row>
		</mat-toolbar>

		<div class="notFound">	
			<h2><strong>Página no encontrada</strong></h2>
			<h3>¿Estás segur@ de lo que estás buscando?</h3>
		</div>
	</body>
</html>