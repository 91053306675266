import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'app-guide',
	templateUrl: './guide.component.html',
	styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

	constructor(
		private _router: Router,
		private _route: ActivatedRoute	
	) {}

	ngOnInit(): void {

	}

	mainPage(){
		this._router.navigate(['/bingo']);
	}

}
