import { NgModule } from '@angular/core';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        MatToolbarModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatCardModule
    ],
    exports: [
        MatToolbarModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatCardModule
    ]
})
export class MaterialModule { }