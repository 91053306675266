import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { routing, appRoutingProviders } from './app.routing';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MaterialModule } from './material.module';

import { AppComponent } from './app.component';
import { BingoComponent } from './components/bingo/bingo.component';
import { RoomComponent } from './components/room/room.component';
import { GuideComponent } from './components/guide/guide.component';
import { NotfoundComponent } from './components/notfound/notfound.component';

// ** TEST **
//const config: SocketIoConfig = { url: 'http://192.168.0.158:6677', options: {} };
// **
//const config: SocketIoConfig = { url: 'https://185.254.207.126', options: {
// ** PROD **
const config: SocketIoConfig = { url: 'srirappcha.com', options: {
    path: '/chat'
  } };
// ** 

@NgModule({
  declarations: [
    AppComponent,
    BingoComponent,
    RoomComponent,
    GuideComponent,
    NotfoundComponent
  ],
  entryComponents:[BingoComponent],
  imports: [
    BrowserModule,
    routing,
    HttpClientModule,
    FormsModule,
    SocketIoModule.forRoot(config),
    MaterialModule
  ],
  providers: [
  	appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
