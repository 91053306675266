import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Socket } from 'ngx-socket-io';
import { Global } from './global';

import { Room } from '../models/room';
import { User } from '../models/user';
import { RandomNumber } from '../models/random';
import { Message } from '../models/message';
import { CountDown } from '../models/countdown';

@Injectable()
export class RoomService{

    public url_room: string;
  
	constructor(
		private socket: Socket,
        private _http: HttpClient
	) {
        this.url_room = Global.url_room;
    }

    getMessagesRoom(roomCode: Number): Observable<any>{
        // Establecemos el formato que le vamos a enviar la información
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url_room + 'messages/' + roomCode, {headers: headers});
    }

    getNewMessagesRoom(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('new-user-connected', (data) => {
                    observer.next(data);
                });
        });
    }

    saveRoom(room: Room): Observable<any>{
        let params = JSON.stringify(room);
        // Establecemos el formato que le vamos a enviar la información
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url_room + 'create-room', params, {headers: headers});
    }

    updateRoom(room: Room): Observable<any>{
        let params = JSON.stringify(room);

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url_room + 'update/' + room._id, params, {headers: headers});
    }

    getRoomByCode(roomCode: Number): Observable<any>{
        // Establecemos el formato que le vamos a enviar la información
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.get(this.url_room + roomCode, {headers: headers});
    }

    deleteRoom(id): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.delete(this.url_room + 'delete/' + id, {headers: headers});
    }

    newRandomNumber(randomNumber: RandomNumber){
        // Enviamos al socket el número obtenido aleatorio, para posteriormente notificar a los usuarios de la sala
        this.socket.emit('new-random-number', randomNumber);
    }

    newCountDownNumber(countDownNumber: CountDown){
        // Enviamos al socket el número del contador, para posteriormente notificar a los usuarios de la sala
        this.socket.emit('new-number-countdown', countDownNumber);
    }

    getNewNumber(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('new-number', (data) => {
                    observer.next(data);
                });
        });
    }

    getCountDownNumber(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('countdown-number', (data) => {
                    observer.next(data);
                });
        });
    }

    singLine(user: User){
        // Informamos de que el usuario ha solicitado una comprobación linea
        this.socket.emit('sing-line', user);
    }

    singBingo(user: User){
        // Informamos de que el usuario ha solictado una comprobación de bingo
        this.socket.emit('sing-bingo', user);
    }

    sendNewMessageRoom(message: Message){
        this.socket.emit('new-message-room', message);
    }

    updateUsersConnected(room: Room){
        this.socket.emit('users-connected', room);
    }

    getNewMessage(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('message', (data) => {
                    observer.next(data);
                });
        });
    }

    getUsersConnected(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('users-connected-updated', (data) => {
                    observer.next(data);
                });
        });
    }    

    getCurrentStateGame(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('alert-game', (data) => {
                    observer.next(data);
                });
        });
    }

    continueGame(roomCode: Number){
        // Informamos de que la partida debe continuar
        this.socket.emit('continue-game', roomCode);
    }

    stopGame(user: User){
        // Informamos de que el bingo que ha cantado el usuario 'user', es correcto y se para el juego
        this.socket.emit('finish-game', user);
    }

    noSingLine(user: User){
        // informamos a todos los jugadores de la sala que no pueden cantar bingo
        this.socket.emit('no-line', user);
    }

    resetSingLine(user: User){
        // Informamos a todos los ugadores que pueden volver a cantar linea
        this.socket.emit('reset-line', user);
    }

    resetGame(user: User){
        // Informamosa a todos los jugadores incluido el que lo ha solicitado que se ha reiniciado la partida
        this.socket.emit('reset-game', user);
    }

    startGame(user: User){
        // Informamosa a todos los jugadores incluido el que lo ha solicitado que va a comenzar un nuevo juego
        this.socket.emit('start-game', user);
    }

    resetButtons(user: User){
        // Informamosa a todos los jugadores incluido el que lo ha solicitado que puede cantar linea o bingo
        this.socket.emit('reset-buttons', user);
    }

    leaveRoom(user: User){
        // Informamos a todos los jugadores que el anfitrión ha salido de su sala, por lo tanto todos salen
        this.socket.emit('leave-room', user);
    }

    /****************************
        GENERATE CARD
    ****************************/
    generateRandomIntervalNumbers(start: number, last: number, rank: number): Array<number>{
        var numbers: Array<number> = [];
        var i = 0;

        while (numbers.length < rank) {
            var random = Math.floor(Math.random() * (last - start)) + start;
            // Comprobar que el número no esté ya introducido
            if (numbers.indexOf(random) == - 1) {
                // -1 está fuera del rango, por lo tanto lo introducimos
                numbers.push(random);
                i ++;
            }
        }

        // Devuelvo los números ordenados
        return (numbers.sort(function(a, b){return a - b;}));
    }

    generateCard(){
        var card = [[], [], []];
        //var card: number[[][][]];

        // Recorremos las columnas (j)
        for (var j = 0; j < 9; j++) {
            var column;

            if (j == 0) {
                // Primera columna
                column = this.generateRandomIntervalNumbers(10 * j + 1, 10 * (j + 1) - 1, 3);
            } else if (j == 8) {
                // Última columna
                column = this.generateRandomIntervalNumbers(10 * j, 10 * (j + 1), 3);
            } else {
                // Resto de columnas
                column = this.generateRandomIntervalNumbers(10 * j, 10 * (j + 1) - 1, 3);
            }

            // Añadir la columna al cartón
            for (var i = 0; i < 3; i++){
                card[i][j] = {
                    'value': column[i],
                    'mark' : false
                }
            }
        }

        // Recorremos las 3 filas del cartón generado, para añadir las casillas vacias
        for (var i = 0; i < 3; i++){
            // var emptyBoxex: Array<number> ;//= [];
            var emptyBoxex = this.generateRandomIntervalNumbers(0, 9, 4);

            for (var j = 0; j < emptyBoxex.length; j++){
                card[i][emptyBoxex[j]].value = -1; 
            }
        }

        return card;
    }
}