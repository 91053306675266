import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Socket } from 'ngx-socket-io';
import { Global } from './global';

import { Room } from '../models/room';
import { User } from '../models/user';
import { Message } from '../models/message';

@Injectable()
export class UserService{

    public url_room: string;
    public url_user: string;
  
	constructor(
		private socket: Socket,
        private _http: HttpClient
	) {
        this.url_room = Global.url_room;
        this.url_user = Global.url_user;
    }

    joinRoom(user: User){
        // Enviamos el usuario mediante el socket para asignarlo a una sala
        this.socket.emit('join-room', user);
    }

    leaveRoomUser(user: User) {
        // Enviamos el usuario para darlo de baja de una sala
        this.socket.emit('leave-user-room', user);
    }

    disconnectUser(){
        this.socket.disconnect();
    }

    requestSocketId(){
        this.socket.emit('request-socket', 'socket-id');
    }

    getSocketId(): Observable<any>{
        return Observable.create((observer) => {
                this.socket.on('socket-id', (data) => {
                    observer.next(data);
                });
        });
    }

    saveUser(user: User): Observable<any>{
        let params = JSON.stringify(user);
        // Establecemos el formato que le vamos a enviar la información
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        //return this._http.post(this.url_room + 'connect-user', params, {headers: headers});
        return this._http.post(this.url_user + 'connect-user', params, {headers: headers});
    }

    updateUser(user: User): Observable<any>{
        let params = JSON.stringify(user);

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.put(this.url_user + 'update/' + user._id, params, {headers: headers});
    }

    deleteUser(id): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.delete(this.url_user + 'delete/' + id, {headers: headers});
    }

    getCurrentUserBySocketId(socketId: String): Observable<any>{
        // Establecemos el formato que le vamos a enviar la información
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        
        return this._http.get(this.url_user + '/' + socketId, {headers: headers});
    }

    newUserConnected(message: Message){
        this.socket.emit('new-user-room', message);
    }
}