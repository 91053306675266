import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BingoComponent } from './components/bingo/bingo.component';
import { RoomComponent } from './components/room/room.component';
import { GuideComponent } from './components/guide/guide.component';
import { NotfoundComponent } from './components/notfound/notfound.component';

// La ruta 404 es '**'
const appRoutes: Routes = [
	{ path: '', component: BingoComponent },
	{ path: 'bingo', component: BingoComponent },
	{ path: 'bingo/room/:code', component: RoomComponent },
	{ path: 'bingo/guide', component: GuideComponent },
	{ path: '**', component: NotfoundComponent }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);