/* Variables reutilizables en varios archivos */
export var Global = {
	// ** TEST **
	//url_user: 'http://192.168.0.158:3700/api/bingo/v1/user/',
	//url_room: 'http://192.168.0.158:3700/api/bingo/v1/room/'
	//**
	//url_user: 'https://185.254.207.126/api/bingo/v1/user/',
	//url_room: 'https://185.254.207.126/api/bingo/v1/room/'
	
	// ** PROD **
	url_user: '/api/bingo/v1/user/',
	url_room: '/api/bingo/v1/room/'
	// **
};