<head>
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
	<link rel="shortcut icon" sizes="16x16" href="/maintenance/favicon-16x16.png" type="image/png" />
</head>

<section id="content">
    <router-outlet></router-outlet>
</section>

<footer>
	<div class="footerContainer">
		Made with <img class="loveFooter" src="../../../assets/img/love.png"> by SrirAppcha &copy; 2020
	</div>
</footer>