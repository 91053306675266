<!DOCTYPE html>
<html lang="es">
<head>
	<meta charset="utf-8">
	<title>Bingo en CasApp</title>
</head>
	<body>

		<mat-toolbar id="app-toolbar" class="backgroundToolbar">
		  	<mat-toolbar-row class="textSpecify">
			    <!-- <span>Bingo en CasApp</span> -->
			    <span>SALA {{roomCode}}</span>

			    <span class="example-fill-remaining-space"></span>
			    <span class="align-center"></span>
			    <span class="spaceButtonsToolbar"></span>
			    <button mat-button class="buttonToolbar" (click)="exitGame()">Salir</button>
			</mat-toolbar-row>
		</mat-toolbar>

		<div class="roomInfo">	
			<p><strong>{{usersConnected}}   </strong> <img class="imgUsers" src="../../../assets/img/team.png"></p>


			<!--<p><strong>{{usersConnected}}</strong> jugador/es conectados a la sala <strong>{{roomCode}}</strong></p>-->
			<!--<p class="textInfo">BIENVENID@ A LA SALA <strong>{{roomCode}}</strong></p>
			<p class="textInfo">Usuarios conectados <strong>{{usersConnected}}</strong></p> -->
		</div>

		<!-- <hr> -->

		<div class="numbersChat">

			<div class="numbersPannel" *ngIf="!viewCountDown"> 
				<div class="mainNumber" id="ball"><strong>{{currentNumber}}</strong></div>

				<div class="lastNumber" id="ball">{{lastNumbers[2]}}</div>
				<div class="lastNumber" id="ball">{{lastNumbers[1]}}</div>
				<div class="lastNumber" id="ball">{{lastNumbers[0]}}</div>
			</div>

			<div class="countdown" *ngIf="viewCountDown">
				<h2>La partida va a comenzar en...</h2>
				<p>{{countDownNumber}}</p>
			</div>	

			<div class="chatRoom" id="chat"  #scrollMe [scrollTop]="scrollMe.scrollHeight">
				<div id="messages" *ngFor="let messageReceived of messageList" >
					<strong>{{messageReceived.user}}</strong><span>{{messageReceived.message}}</span>
				</div>
			</div>

			<div class="chat">
				<button mat-button [matMenuTriggerFor]="menu"><mat-icon class="whiteChatIcon">chat</mat-icon></button>
				<mat-menu #menu="matMenu">
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Hola</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Bienvenid@</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Mucha suerte a tod@s</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Todo lo mejor</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Agita el bombo</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">No salen mis números</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Cantaré BINGO</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">La línea es mía</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">El bingo es mío</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Hoy no es mi día</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Jejeje</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Quiero otra partida</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">¿Estáis preparad@s?</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Estoy preparad@</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Ojú</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Venga</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Sí, claro</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">¡Que salgan mis números!</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">¡Vamos!</button>
				  <button mat-menu-item (click)="sendMessageChat($event.target)" class="textSpecify">Estoy en racha</button>
				</mat-menu>
			</div>
		</div>

		<div class="buttons">
			<button (click)="singLine($event)" [disabled]="!canSingLine">LINEA</button>
			<button (click)="singBingo($event)" [disabled]="!canSingBingo">BINGO</button>
			<button (click)="changeCard($event)" [disabled]="!canChangeCard">CAMBIAR CARTÓN</button>

			<button (click)="startGame($event)" [disabled]="!newGame" *ngIf="room && isOwner">EMPEZAR</button>
			<button (click)="resetGame($event)" [disabled]="newGame" *ngIf="room && isOwner">REINICIAR</button>	
		</div>

		<div>
			<table *ngIf="user" class="card">
				<tr>
					<td *ngFor="let numberR1 of user.card.row1" [ngClass]="{'logoCard': numberR1.value=='-1', 'checkCell': numberR1.mark == true}" (click)="markCell('r1', numberR1.value)">
						<p *ngIf="numberR1.value != -1">
							<strong>{{numberR1.value}}</strong></p></td>
				</tr>
				<tr>
					<td *ngFor="let numberR2 of user.card.row2" [ngClass]="{'logoCard': numberR2.value=='-1', 'checkCell': numberR2.mark == true}" (click)="markCell('r2', numberR2.value)">
						<p *ngIf="numberR2.value != -1">
							<strong>{{numberR2.value}}</strong></p></td>
				</tr>
				<tr>
					<td *ngFor="let numberR3 of user.card.row3" [ngClass]="{'logoCard': numberR3.value=='-1', 'checkCell': numberR3.mark == true}" (click)="markCell('r3', numberR3.value)" >
						<p *ngIf="numberR3.value != -1">
							<strong>{{numberR3.value}}</strong></p></td>
				</tr>
			</table>
		</div>

	</body>
</html>