import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { RoomService } from '../../services/room.service';
import { UserService } from '../../services/user.service';

import { User } from '../../models/user';
import { Room } from '../../models/room';
import { RandomNumber } from '../../models/random';
import { Message } from '../../models/message';
import { CountDown } from '../../models/countdown';

@Component({
	selector: 'app-room',
	templateUrl: './room.component.html',
	styleUrls: ['./room.component.css'],
	providers: [RoomService, UserService]
})
export class RoomComponent implements OnInit{

	public user: User;
	public room: Room;
	public meessage: Message;

	public roomCode: number;
	public usersConnected: Number;
	public messageList:  object[] = [];
	public currentNumber: Number;
	public interval;
	public intervalCountDown;
	public continue: boolean;
	public continueCountDown: boolean;
	public newGame: boolean;
	public lastNumbers: Number[] = [];
	public canSingLine: boolean;
	public canSingBingo: boolean;
	public isOwner: boolean;
	public countDownNumber: number;
	public viewCountDown: boolean;
	public canChangeCard: boolean;

	constructor(
		private _roomService: RoomService,
		private _userService: UserService,
		private _router: Router,
		private _route: ActivatedRoute,
		private cdref: ChangeDetectorRef
	) {
		this.lastNumbers = [0, 0, 0];
		this.newGame = true;
		this.canSingLine = false;
		this.canSingBingo = false;
		this.isOwner = false;
		this.currentNumber = 0;
		this.viewCountDown = false;
		this.canChangeCard = true;
	}

	ngOnInit(): void {
		// Obtenemos el nombre de la sala de la ruta
		this._route.params.subscribe(params => {
			let code = params.code;
			this.roomCode = code;
		});

		// Obtenemos la sala por el código, posteriormente nos servirá para comprobar si el usuario es owner
		this._roomService
			.getRoomByCode(this.roomCode)
			.subscribe(
				response => {
					this.room = response.room[0];

					if (response.room.length == 0) {
						this._router.navigate(['/bingo']);
					} else {
						this.usersConnected = this.room.usersConnected;
						if (this.room.numsTotal.length < 1) {
							this.newGame = false;
						}
					}	

					// Obtenemos el usuario actual
					// Mandamos al socket la petición para que nos devuelva el socket-id para buscarlo en la base de datos
					this._userService.requestSocketId();

					// Obtenemos el socket-id
					this._userService
						.getSocketId()
						.subscribe((data: String) => {
							// Obtenemos el usuario actual
							this._userService
								.getCurrentUserBySocketId(data)
								.subscribe(
									response => {
										if (response.user[0]){
											this.user = response.user[0];
											if (this.user.socketId == this.room.owner) {
												this.isOwner = true;
											}
										} else {
											this._router.navigate(['/bingo']);
										}
									},
									error => {
										console.log(error);
									}
								);	
					});

				},
				error => {
					console.log(error);
				}
			);

		// Actualizamos la bola con los números que nos notifican
		this._roomService
      		.getNewNumber()
      		.subscribe((newNumber: Number) => {
      			this.viewCountDown = false;
      			this.lastNumbers.push(this.currentNumber);
				this.lastNumbers.splice(0, 1);
      			// Actualizamos la bola
      			this.updateBall(newNumber);
      			// Ejecutamos el audio
      			this.playSound(newNumber);
      	});

      	// Obtenemos los nuevos mensajes/actualizaciones de la partida
      	this._roomService
      		.getNewMessage()
      		.subscribe((message: string) => {
      			if (message.includes(':')){
      				this.messageList.push({
      					user: message.split(':')[0] + ':',
      					message: message.split(':')[1]
      				});	
      			} else {
      				this.messageList.push({
      					user: message.split(':')[0],
      					message: ''
      				});
      			}
   				
   				// Ejecutamos el sonido de notificacion
   				this.playNotification();
      	});

      	// Actualizamos los números de la cuenta atrás
  		this._roomService
	  		.getCountDownNumber()
	  		.subscribe((newNumber: number) => {
	  			// No podemos cambiar el cartón
	  			this.canChangeCard = false;
  				this.updateCountDown(newNumber);
      	});

	  	// Actualizamos el número de usuarios conectados
	  	this._roomService
	  		.getUsersConnected()
	  		.subscribe((users: string) => {
	  			this.updateUsersConnected(users);
	  		});

      	// Obtenemos toas las actualizaciones del juego (si alguien canta linea o bingo, ...)
      	this._roomService
      		.getCurrentStateGame()
      		.subscribe((state: string) => {
      			switch(state) {
      				case 'pause':
      					// console.log("Se va a pausar el juego");
      					this.continue = false;
      					break;
      				case 'continue':
      					// console.log("Se va a continuar el juego");
      					this.continue = true;
      					break;
      				case 'end':
      					// console.log("El juego ha terminado");
      					// Se puede reiniciar el juego
      					this.newGame = false;
      					this.continue = false;
      					// Podemos cambiar el cartón
      					this.canChangeCard = true;
      					// No podemos cantar ni línea ni bingo
      					this.canSingLine = false;
      					this.canSingBingo = false;
      					break;
      				case 'noline':
      					// console.log("Ya no se puede cantar más linea");
      					this.canSingLine = false;
      					break;
      				case 'resetline':
      					// console.log("Se puede volver a cantar línea");
      					this.canSingLine = true;
      					this.lastNumbers = [0, 0, 0];
      					this.currentNumber = 0;
      					break;
      				case 'resetgame':
      					// Se reinician los marcadores del carton
      					this.resetMarks(this.user);
      					// console.log("Se ha reiniciado la partida partida");
      					// Se reinician los números
      					this.lastNumbers = [0, 0, 0];
      					this.currentNumber = 0;
      					// No se permite cantar ni bingo ni linea
      					this.canSingLine = false;
      					this.canSingBingo = false;
      					// No se permite continuar
      					this.continue = false;
      					// Se permite cambiar el cartón
      					this.canChangeCard = true;
      					// Reiniciar interval
      					clearInterval(this.interval);
      					break;
      				case 'startgame':
      					// console.log("Se va a comenzar el juego");
      					// Se muestra el contador
      					this.viewCountDown = true;
      					this.newGame = false;
      					break;
      				case 'resetbuttons':
      					// console.log("Reinciar los botones de linea y bingo al empezar la partida");
      					// Se permite cantar linea y bingo
      					this.canSingLine = true;
      					this.canSingBingo = true;
      					break;
      				case 'closeroom':
      					//console.log("Se cierra la sala");
      					this.exitGameCausedOwner();
      					break;
      				default:
      					console.log("Error en la instrucción recibida");
      					break;
      			}
      	});
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}

	startGame(event){
		// Ver y establecer el contador
		var countDownNumber = 10;
		this.viewCountDown = true;
		// No puede cambiar el cartón
		this.canChangeCard = false;
		// Una vez actualizado los datos, informamos a todos los usuarios incluido el propietario para que se abra el contador
		this._roomService.startGame(this.user);

		// Cuenta atrás
		this.continueCountDown = true;
		this.intervalCountDown = setInterval(() => {
			if (this.continueCountDown) {
					if (countDownNumber >= 0) {
						// Actualizamos el número en el html
						this.updateCountDown(countDownNumber);

						var countDownUpdate : CountDown = new CountDown(countDownNumber, this.roomCode);

						// Notificar mediante socket
						this._roomService.newCountDownNumber(countDownUpdate);

						countDownNumber --;
					} else {
						this.continueCountDown = false;
						this.viewCountDown = false;
						clearInterval(this.intervalCountDown);	
						// Una vez termine la cuenta atrás, podemos avisar para que los botones de cantar linea y bingo esten disponibles
						this._roomService.resetButtons(this.user);
						this.startGetRandomRumbers();
					}
			}
		}, 1000);
	}

	startGetRandomRumbers(){
		this.continue = true;
		this.interval = setInterval(() => {
			if (this.continue) {
				this.getRandomNumber(this.roomCode);
			}
		}, 3000);
	}

	updateCountDown(countDownNumber) {
		this.countDownNumber = countDownNumber;
	}

	updateUsersConnected(numberUsers){
		this.usersConnected = numberUsers;
	}

	getRandomNumber(roomCode) {
		var currentRoom: Room;
		var randomNumber: Number;

		this._roomService
			.getRoomByCode(roomCode)
			.subscribe(
				response => {
					currentRoom = response.room[0];

					if (currentRoom.numsTotal.length > 0) {
						// Actualizar la lista de números
						this.lastNumbers.push(this.currentNumber);
						this.lastNumbers.splice(0, 1);

						var random = Math.floor(Math.random() * (currentRoom.numsTotal.length));

						randomNumber = currentRoom.numsTotal[random];

						// Actualizamos la bola del que lo ha solicitado
						this.updateBall(randomNumber);
						// Ejecutamos el audio
						this.playSound(randomNumber);

						var randomNumberObject: RandomNumber = new RandomNumber(randomNumber, this.roomCode);

						// Notificar mediante socket
						this._roomService.newRandomNumber(randomNumberObject);

						// Eliminar el número del array numsTotal y pasarlo a numsPlayed
						currentRoom.numsPlayed.push(randomNumber);
						currentRoom.numsTotal.splice(random, 1);

						// Actualizar la sala
						this._roomService
							.updateRoom(currentRoom)
							.subscribe (
								response => {
	      							// console.log("Numeros de la sala actualizada");
	      						},
	      						error => {
	      							console.log(error);
	      						}
							);
					} else {
						// Se ha terminado la partida
						this.newGame = false;
						// this.updateBall(0);
						this.continue = false;
						clearInterval(this.interval);
						// Informamos a todos los usuarios que se ha terminado la partida
						// Paramos el juego, si el bingo es correcto
						this._roomService
							.stopGame(this.user);
					}
				},
				error => {
					console.log(error);
				}
			);
	}

	updateBall(number: Number){
		this.currentNumber = number;
	}

	playSound(audioNumber: Number){
		var audio = new Audio();
		audio.src = "../../../assets/audio/esp/" + audioNumber + ".mp3";
		audio.load();
		audio.play();
	}

	playNotification(){
		var audio = new Audio();
		audio.src = "../../../assets/audio/notification.mp3";
		audio.load();
		audio.play();
	}

	resetGame(event){
		// Informamos a los usuarios que se ha reiniciado la partida
		this._roomService.resetGame(this.user);
		// No podemos dar de nuevo al botón de empezar
		this.newGame = true;

		var currentRoom: Room; 

		// Actualizar las bolas de la sala
		this._roomService
			.getRoomByCode(this.roomCode)
			.subscribe (
				response => {
					currentRoom = response.room[0];

					currentRoom.numsTotal.splice(0, currentRoom.numsTotal.length);

					for (let i = 1; i <= 90; i++) {
						currentRoom.numsTotal.push(i);
					}

					currentRoom.numsPlayed.splice(0, currentRoom.numsPlayed.length);

					// Actualizar la sala
					this._roomService
						.updateRoom(currentRoom)
						.subscribe (
							response => {
      							// console.log("Números de la sala reiniciados");
      						},
      						error => {
      							console.log(error);
      						}
						);

				},
				error => {
					console.log(error);
				}
			);

		// Reset value
		this.newGame = true;
		this.updateBall(0);
	}

	singLine(event){
		var currentRoom: Room;
		// Informar a todos los usuarios de la sala y pausar a todos
		this.continue = false;
		// Informamos
		this._roomService.singLine(this.user);

		// Comprobar línea, comprobamos el usuario actual (this.user) con una sala obtenida recientemente por si cumple
		this._roomService
			.getRoomByCode(this.roomCode)
			.subscribe(
				response =>{
					currentRoom = response.room[0];

					if (this.checkLineCard(currentRoom.numsPlayed, this.user.card)){
						// Ya no se puede cantar más linea
						this._roomService
							.noSingLine(this.user);
						// Enviamos una notificación a la sala
						let text: String = this.user.name + ": La línea ha sido correcta";
						let message: Message = new Message(this.user.roomId, this.user.name, text);
						this._roomService.sendNewMessageRoom(message);
					} else {
						// Enviamos una notiicación a la sala
						let text: String = this.user.name + ": La línea ha sido incorrecta";
						let message: Message = new Message(this.user.roomId, this.user.name, text);						
						this._roomService.sendNewMessageRoom(message);
					}

					// Continuar el juego
					this._roomService
						.continueGame(this.roomCode);
				},
				error => {
					console.log(error);
				}
			);
	}

	checkLineCard(numsPlayed, card): boolean{
		var row1: number = 0;
		var row2: number = 0;
		var row3: number = 0;

		// Comprobar cada línea con el array de números que han salido
		// Cuando se encuentre una línea que todos hayan salido se devuelve true, sino nos

		// Línea 1
		for (var i = 0; i < card.row1.length; i++){
			if (numsPlayed.indexOf(card.row1[i].value) != -1) {
				row1++;
			}
		}

		// Línea 2
		for (var j = 0; j < card.row2.length; j++){
			if (numsPlayed.indexOf(card.row2[j].value) != -1) {
				row2++;
			}
		}

		// Línea 3
		for (var h = 0; h < card.row3.length; h++){
			if (numsPlayed.indexOf(card.row3[h].value) != -1) {
				row3++;
			}
		}

		if (row1 == 5 || row2 == 5 || row3 == 5) {
			return true;
		} else {
			return false;
		}
	}

	singBingo(event){
		var currentRoom: Room;
		// Informar a todos los usuarios de la sala y pausar a todos
		this.continue = false;
		// Informamos
		this._roomService.singBingo(this.user);

		// Comprobar línea, comprobamos el usuario actual (this.user) con una sala obtenida recientemente por si cumple
		this._roomService
			.getRoomByCode(this.roomCode)
			.subscribe(
				response =>{
					currentRoom = response.room[0];

					if (this.checkBingoCard(currentRoom.numsPlayed, this.user.card)){
						// Enviamos una notificación a la sala
						let text: String = this.user.name + ": El bingo ha sido correcto";
						let message: Message = new Message(this.user.roomId, this.user.name, text);
						this._roomService.sendNewMessageRoom(message);
						// Paramos el juego, si el bingo es correcto
						this._roomService
							.stopGame(this.user);
					} else {
						// Mandamos una notificación a la sala
						let text: String = this.user.name + ": El bingo ha sido incorrecto";
						let message: Message = new Message(this.user.roomId, this.user.name, text);
						this._roomService.sendNewMessageRoom(message);
						// Continuar el juego, si el bingo es incorrecto
						this._roomService
							.continueGame(this.roomCode);
					}
				},
				error => {
					console.log(error);
				}
			);

	}

	checkBingoCard(numsPlayed, card): boolean{
		var row1: number = 0;
		var row2: number = 0;
		var row3: number = 0;

		// Comprobar cada línea con el array de números que han salido

		// Línea 1
		for (var i = 0; i < card.row1.length; i++){
			if (numsPlayed.indexOf(card.row1[i].value) != -1) {
				row1++;
			}
		}

		// Línea 2
		for (var j = 0; j < card.row2.length; j++){
			if (numsPlayed.indexOf(card.row2[j].value) != -1) {
				row2++;
			}
		}

		// Línea 3
		for (var h = 0; h < card.row3.length; h++){
			if (numsPlayed.indexOf(card.row3[h].value) != -1) {
				row3++;
			}
		}

		// Las tres líneas deben ser correctas, es decir = 9
		if (row1 == 5 && row2 == 5 && row3 == 5) {
			return true;
		} else {
			return false;
		}
	}

	markCell(row, number){
		var index;

		if (number != -1) {
			switch(row) {
				case 'r1':
					index = this.user.card.row1.findIndex(i => i.value === number);
					this.user.card.row1[index].mark = (this.markValue(this.user.card.row1[index].mark));
					break;
				case 'r2':
					index = this.user.card.row2.findIndex(i => i.value === number);
					this.user.card.row2[index].mark = (this.markValue(this.user.card.row2[index].mark));
					break;
				case 'r3':
					index = this.user.card.row3.findIndex(i => i.value === number);
					this.user.card.row3[index].mark = (this.markValue(this.user.card.row3[index].mark));
					break;
				default:
					console.log("Error al seleccionar celda del tablero");
					break;
			}
		}
	}

	markValue(value): boolean {
		if (value) {
			return false;
		} else {
			return true;
		}
	}

	resetMarks(user: User){
		// Reiniciar bingo
		for (var i = 0; i < user.card.row1.length; i++){
			user.card.row1[i].mark = false;
			user.card.row2[i].mark = false;
			user.card.row3[i].mark = false;
		}
	}

	changeCard(event){
		var card = this._roomService.generateCard();

		this.user.card.row1 = card[0];
		this.user.card.row2 = card[1];
		this.user.card.row3 = card[2];

		// Actualizar el usuario
		this._userService
			.updateUser(this.user)
			.subscribe (
				response => {
						// console.log("Usuario actualizado correctamente");
					},
					error => {
						console.log(error);
					}
			);
	}

	sendMessageChat(element){
		// Enviamos el mensaje a la sala
		let text: String = this.user.name + ": " + element.textContent;
		let message: Message = new Message(this.user.roomId, this.user.name, text);
		this._roomService.sendNewMessageRoom(message);
	}

	exitGame(){
		// Avisa de que el usuario se ha desconectado
		let text: String = this.user.name + " se ha desconectado";
		let message: Message = new Message(this.user.roomId, this.user.name, text);
		this._roomService.sendNewMessageRoom(message);

		if (this.isOwner) {
			// Si es anfitrión se elimina la sala
			this._roomService
			.deleteRoom(this.room._id)
			.subscribe(
				response => {
					// console.log("Sala eliminada correctamente");
					this._roomService.leaveRoom(this.user);
				},
				error => {
					console.log(error);
				}
			);
		} else {
			// Actualizar el número de usuarios conectados
			this.room.usersConnected = this.room.usersConnected - 1

			this._roomService
			.updateRoom(this.room)
			.subscribe(
  				response => {
  					this._roomService.updateUsersConnected(this.room);
  				},
				error => {
					console.log(error);
				}
	  		);
		}

		this._userService
			.deleteUser(this.user._id)
			.subscribe(
				response => {
					// console.log("Usuario eliminado correctamente");
					// Damos de baja al usuario de la sala en el socket
					this._userService.leaveRoomUser(this.user);

					this._router.navigate(['/bingo']);
				},
				error => {
					console.log(error);
				}
			);
	}

	exitGameCausedOwner(){
		this._userService
			.deleteUser(this.user._id)
			.subscribe(
				response => {
					// console.log("Usuario eliminado correctamente");
					// Damos de baja al usuario de la sala en el socket
					this._userService.leaveRoomUser(this.user);

					this._router.navigate(['/bingo']);
				},
				error => {
					console.log(error);
				}
			);
	}
}