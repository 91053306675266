<!DOCTYPE html>
<html lang="es">
<head>
	<meta charset="utf-8">
	<title>Bingo en CasApp</title>
</head>
	<body>
		<mat-toolbar id="app-toolbar" class="backgroundToolbar">
		  	<mat-toolbar-row class="textSpecify">
			    <span>Bingo en CasApp</span>

			    <span class="example-fill-remaining-space"></span>
			    <span class="align-center"></span>
			    <span class="spaceButtonsToolbar"></span>
			    <button mat-button class="buttonToolbar" (click)="mainPage()">Inicio</button>
			</mat-toolbar-row>
		</mat-toolbar>

		<div class="guideTitlenfo">	
			<h2><strong>Guía para jugar a Bingo en CasApp</strong></h2>
		</div>

		<mat-card class="instructionCard">

		  <mat-card-header class="textSpecify">
		    <mat-card-title>Organizar una partida</mat-card-title>
		  </mat-card-header>

		  <mat-card-content class="textSpecify">
		  	<ol>
		  		<li>
		  			Introduce tu nombre que te servirá como identificación en la sala de juego. El sistema te generará un código de
		  			8 dígitos, será muy importante que lo guardes ya que lo tendrás que compartir con tus amig@s para poder jugar junt@s. Haz clic en crear para generar la sala de juego.

		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/01.png" alt="Anfitrión de una sala">
		  		<li>
		  			A diferencia de otro jugador, el anfitrión tiene dos botones adicionales para gestionar el juego EMPEZAR y REINICIAR. El primero de ellos permite al anfitrión comenzar la partida, el botón de REINICIAR reestablece los
		  			valores de los cartones de todos los jugadores y los números del juego.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/03.png" alt="Botones de gestión">
		  	</ol>
		  </mat-card-content>
		</mat-card>

		<mat-card class="instructionCard">

		  <mat-card-header class="textSpecify">
		    <mat-card-title>Unirme a una partida</mat-card-title>
		  </mat-card-header>

		  <mat-card-content class="textSpecify">
		  	<ol>
		  		<li>
		  			Introduce el código de la sala proporcionado por el anfitrión y tu nombre, este será tu identificación en el juego. Por último haz clic en el botón UNIRME, serás reedirigid@ a la sala con el resto de jugadores.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/02.png" alt="Unirme a una sala">
		  	</ol>
		  </mat-card-content>
		</mat-card>

		<mat-card class="instructionCard">

		  <mat-card-header class="textSpecify">
		    <mat-card-title>Como jugar</mat-card-title>
		  </mat-card-header>

		  <mat-card-content class="textSpecify">
		  	<ul>
		  		<li>
		  			En el panel superior de la sala aparecerá el código de la sala en la que estás jugando, será útil para que nunca pierdas la referencia y puedas compartirlo con tus amig@s. Para salir de la sala deberás hacerlo mediante el botón Salir.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/10.png" alt="Panel superior">
		  		<li>
		  			Debes estar pendiente a los números que van aparenciendo, la bola grande indica el último número que ha salido. También se muestran los tres últimos números en las tres bolas de menor tamaño.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/09.png" alt="Bolas bingo">
		  		<li>
		  			Cuando aparezca un número que tienes en tu cartón, lo deberás marcar pulsando sobre el. Cuando el color cambie a gris, indicará que ha sido marcado. Para desmarcarlo, deberás pulsar de nuevo.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/05.png" alt="Cartón bingo">
		  		<li>
		  			Si tienes una línea o todo el cartón marcado puedes cantar línea o bingo con los botones que aprecen en la parte superior del cartón. También antes o al finalizar una partida podrás cambiar tu cartón tantas veces como quieras.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/04.png" alt="Botones bingo">
		  		<li>
		  			A la derecha del panel de las bolas, dispones de un chat para conocer los usuarios que se han conectado o desconectado, las líneas y bingos correctos o incorrectos, así como enviar mensajes predefinidos al resto de usuarios.
		  		</li>
		  		<img class="imageTutorial" src="../../../assets/img/guide/12.png" alt="Chat bingo">
		  	</ul>
		  </mat-card-content>
		</mat-card>
	</body>
	
</html>