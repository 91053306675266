import { Card } from './card';

export class User{

	constructor(
		public _id: string,
		public socketId: string,
		public roomId: Number,
		public name: String,
		public card: Card
	){}
};